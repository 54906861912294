<template>
   <main>
		<HeaderTab 
            :title="$t('global.planning_task_todo')" 
        />
        <div id="content">
            <ShutterPanel />
        </div>
    </main>
</template>

<script type="text/javascript">
    import Shutter from '@/mixins/Shutter.js'
	import ShutterAct from "@/mixins/shutters-manager/Acts.js"
    import ShutterPanel from 'GroomyRoot/components/Utils/ShutterPanel'

	export default {
		name: 'TaskPlanning',
		mixins: [Shutter, ShutterAct],
		data () {
			return {
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
                this.setupTaskPlanning()
			},
		},

		components: {	
			HeaderTab: () => import('@/components/HeaderTab'),
			ShutterPanel
		}
	}

</script>